<template>
  <el-drawer
    title="客户分组"
    :visible.sync="visible"
    direction="rtl"
    size="70%"
  >
    <div style="padding: 0 10px">
      <el-transfer
        style="width: 100%"
        filterable
        :filter-method="filterMethod"
        filter-placeholder="请输入客户名称"
        v-model="value"
        :data="data"
        :titles="titles"
        :props="{
          key: 'Id',
          label: 'cCusName',
        }"
      >
      </el-transfer>
    </div>
    <div style="height: 60px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    parentObj: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      data: [],
      value: [],
      titles: ["未分组", "已分组"],
      btnLoading: false,
      groupId: "",
    };
  },
  methods: {
    open(companyId, Id, GroupName) {
      this.value = [];
      this.data = [];
      this.companyId = companyId;
      this.titles[1] = GroupName;
      this.groupId = Id;
      this.visible = true;
      this.getCustomerList();
      this.getGroupCustomer();
    },
    handleSubmit() {
      this.btnLoading = true;
      let arr = this.value.map((item) => {
        return {
          CusId: item,
          CcusCode: this.data.find((im) => item == im.Id).cCusCode,
        };
      });
      this.$http
        .post("/BO/CustomerGroup/UpdateCusGroup", {
          groupid: this.groupId,
          groupLists: arr,
        })
        .then((res) => {
          this.btnLoading = false;
          if (res.Success) {
            this.parentObj.getDataList();
            this.$message.success("操作成功");
            this.visible = false;
          } else this.$message.error(res.Msg);
        });
    },
    filterMethod(query, item) {
      if (item.CcusName) return item.CcusName.indexOf(query) > -1;
      else if(item.cCusName) return item.cCusName.indexOf(query) > -1;
    },
    getCustomerList() {
      this.$http
        .post("/BO/CustomerInfo/GetDataList", {
          Search: { SupplieId: this.companyId },
        })
        .then((res) => {
          if (res.Success) {
            this.data = res.Data;
          }
        });
    },
    getGroupCustomer() {
      this.$http
        .post("BO/CustomerGroup/GetCusGrouped", {
          Search: {
            GroupId: this.groupId,
          },
        })
        .then((res) => {
          if (res.Success) this.value = res.Data.map((item) => item.CusId);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-transfer-panel {
  width: 400px;
  height: 500px;
}
/deep/.el-transfer-panel__list.is-filterable {
  height: 397px;
}
</style>