<template>
  <div>
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.condition"
          placeholder="请输入编号/名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%"
        :max-height="height"
      >
        <el-table-column prop="GroupName" label="分组名称" width="300">
        </el-table-column>
        <el-table-column prop="GroupName" label="创建日期" width="100">
          <template slot-scope="scope">{{
            scope.row.CreateTime.substring(0, 10)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="Count"
          label="客户总数"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="Remark" label="备注"> </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="marginRight"
              @click="handleEdit(scope.row)"
            >
              分组
            </el-button>
            <el-popconfirm
              title="此操作将永久删除当前数据,确认继续吗?"
              @confirm="del(scope.row.Id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增分组"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分组名称">
          <el-input v-model="form.GroupName"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.Remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confrimGroup">确 定</el-button>
      </span>
    </el-dialog>
    <GroupForm ref="groupForm" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import GroupForm from "./groupForm.vue";
import radioGroup from "@/components/CompanyRadioGroup";
export default {
  components: {
    radioGroup,
    GroupForm,
  },
  data() {
    return {
      form: {},
      dialogVisible: false,
      loading: false,
      tableData: [],
      queryParam: { condition: "", SupplieId: "全部" },
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  mounted() {},

  methods: {
    del(id) {
      this.$http.post("/BO/CustomerGroup/DeleteData", [id]).then((res) => {
        if (res.Success) {
          this.getDataList();
          this.$message.success("操作成功");
        } else this.$message.error(res.Msg);
      });
    },
    handleClose(done) {
      this.form = {};
      done();
    },
    confrimGroup() {
      if (!this.form.GroupName) return this.$message.error("请填写分组名称");
      this.$http
        .post("/BO/CustomerGroup/SaveData", {
          ...this.form,
          CompanyId: this.queryParam.SupplieId,
        })
        .then((res) => {
          if (res.Success) {
            this.getDataList();
            this.form = {};
            this.$message.success("操作成功");
            this.dialogVisible = false;
          } else this.$message.error(res.Msg);
        });
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length == 1) {
        this.dialogVisible = true;
      } else this.$message.error("请选择公司");
    },
    handleEdit(row) {
      this.$refs.groupForm.open(row.CompanyId, row.Id, row.GroupName);
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = false;
      this.$http
        .post("/BO/CustomerGroup/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData.length > 0) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-item.is-active {
    color: #1aad19;
}
/deep/ .el-menu-item.is-active {
    color: #1aad19;
}
.el-button--primary {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/.el-radio-button__inner:hover {
    color: #1aad19;
}
.marginRight {
  margin-right: 10px;
}
</style>